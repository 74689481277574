<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="8"
      >
        <b-card
          :title="$t('Create New Tcompany')"
        >
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <b-form-input
                  v-model="data.name"
                  class="mt-2"
                  :state="errors.name ?false:null"
                  :placeholder="$t('name')"
                />
                <small
                  v-if="errors.name"
                  class="text-danger"
                >{{ errors.name[0] }}</small>
                <label>{{ $t('name') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <b-form-input
                  v-model="data.body"
                  class="mt-2"
                  :state="errors.body ?false:null"
                  :placeholder="$t('body')"
                />
                <small
                  v-if="errors.body"
                  class="text-danger"
                >{{ errors.body[0] }}</small>
                <label>{{ $t('body') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <b-form-input
                  v-model="data.phone"
                  class="mt-2"
                  :state="errors.phone ?false:null"
                  :placeholder="$t('phone')"
                />
                <small
                  v-if="errors.phone"
                  class="text-danger"
                >{{ errors.phone[0] }}</small>
                <label>{{ $t('phone') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <b-form-input
                  v-model="data.adress"
                  class="mt-2"
                  :state="errors.adress ?false:null"
                  :placeholder="$t('adress')"
                />
                <small
                  v-if="errors.adress"
                  class="text-danger"
                >{{ errors.adress[0] }}</small>
                <label>{{ $t('adress') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <b-form-input
                  v-model="data.maplan"
                  class="mt-2"
                  :state="errors.maplan ?false:null"
                  :placeholder="$t('maplan')"
                />
                <small
                  v-if="errors.maplan"
                  class="text-danger"
                >{{ errors.maplan[0] }}</small>
                <label>{{ $t('maplan') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <b-form-input
                  v-model="data.maplat"
                  class="mt-2"
                  :state="errors.maplat ?false:null"
                  :placeholder="$t('maplat')"
                />
                <small
                  v-if="errors.maplat"
                  class="text-danger"
                >{{ errors.maplat[0] }}</small>
                <label>{{ $t('maplat') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <b-form-input
                  v-model="data.email"
                  class="mt-2"
                  :state="errors.email ?false:null"
                  :placeholder="$t('email')"
                />
                <small
                  v-if="errors.email"
                  class="text-danger"
                >{{ errors.email[0] }}</small>
                <label>{{ $t('email') }}</label>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-row>

          <b-col cols="12">
            <b-card>
              <b-row>
                <b-col
                  cols="12"
                >
                  <b-button
                    class="w-100 mb-1"
                    variant="gradient-success"
                    @click="create"
                  >
                    {{ $t('Create') }}
                  </b-button>
                  <b-button
                    class="w-100"
                    variant="gradient-danger"
                    @click="data={},errors=[]"
                  >
                    {{ $t('Reset Form') }}
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-col>

    </b-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // options: [
      //   { value: 'tourism', text: 'سياحية' },
      //   { value: 'small', text: 'نقل صغيرة' },
      //   { value: 'big', text: 'نقل كبيرة' },
      //   { value: 'transport_ride', text: 'نقل وركوب' },
      //   { value: 'agricultural', text: 'زراعية' },
      //   { value: 'work', text: 'أشغال' },
      //   { value: 'microbus', text: 'ميكروباص' },
      //   { value: 'motor', text: 'دراجة نارية' },
      // ],
      data: {},
      errors: [],
      fileErrors: '',

    }
  },
  computed: {},
  created() {},
  methods: {
    setBoolean(val) {
      if (this.data[val] === undefined) { this.data[val] = false }
    },
    UpdateFileError(variable) {
      this.fileErrors = variable
    },
    create() {
      this.errors = []
      this.$store.dispatch('tcompany/Create', this.data).then(() => {
        this.data = {}
      }).catch(error => {
        this.errors = error.response.data.errors
      })
    },
  },
}
</script>
